.requestButton {
    padding: 20px;
    font-weight: 500;
    text-decoration: underline;
    font-size: 17px;
    cursor: pointer;
    text-decoration-thickness: 2px;
}


.alignDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    gap: 24px;
}

.alignItems {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
}

.bgImage {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.imagePosition {
    margin-left: -180px;
}

.alignFooterContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    padding: 30px;
}